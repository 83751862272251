<template>
	<div class="">
		<div class="tableCon" v-loading="loading_load" :element-loading-text="$t('47df8be257c59dde')">
			<div class="tableConTable">
				<div class="tableConTop">
					<el-row>
						<el-col :span="5" class="tableConTopLeft">
							<h3>
								<span class="tct_tit">{{$t('i18nn_e4713d4209db1f88')}}</span>
								<!-- <el-tooltip effect="dark" :content="$t('i18nn_dc14da6ede09b7f1')" placement="top"> -->
								<!-- <el-button type="primary" circle icon="el-icon-setting" size="mini" @click="selShowHeader()" ></el-button> -->
								<!-- </el-tooltip> -->
								<!-- <el-tooltip effect="dark" :content="$t('i18nn_90b7f7d6846dfdce')" placement="top">
									<el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcelAction()"></el-button>
								</el-tooltip>
								<el-tooltip effect="dark" :content="$t('i18nn_4e55f242c71cef9a')" placement="top">
									<el-button type="primary" circle icon="el-icon-download" size="mini" @click="openDownCodePDF()"></el-button>
								</el-tooltip> -->

							</h3>
						</el-col>
						<el-col :span="19" class="tableConTopRig">
							<!-- <el-button  @click="updatePartnerDataAction($event,$t('4e9fc68608c60999'))" type="danger" size="small" icon="el-icon-close">{{$t('i18nn_4f62f8d07bb4054f')}}</el-button> -->
							<el-button v-if="!isSel" @click="openAdd($event)" type="primary" size="small"
								icon="el-icon-plus">{{ $t('c0246c55b9cac963') }}</el-button>

							<!-- <span v-if="!isSel"> -->
							<!-- <el-button @click="completeAction(null)" type="danger" size="small" icon="el-icon-finished">{{$t('i18nn_785c25ad46aa1a92')}}</el-button> -->
							<!-- <el-button v-if="!isSel" @click="delAction($event, null)" type="danger" size="small" icon="el-icon-minus">{{ $t('e33c9b93c36fd250') }}</el-button> -->
							<!-- </span> -->
							<!--右边容器-->
							<!-- <slot name="topRight"></slot> -->
							<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button>
						</el-col>
					</el-row>
				</div>

				<div>
					<div class="filterCon">
						<!-- <el-row type="flex" justify="end"> -->
						<!-- <el-col :span="24"> -->
						<ul class="filterConList">
							<!-- <li>
                    <span>{{$t('i18nn_cabc5dc617eef30e')}}</span>

                    <el-date-picker
                      v-model="filterData.statisticsCurrentDate"
                      value-format="yyyy-MM-dd"
                      type="date"
                      size="small"
                      :placeholder="$t('68efabd029c860a9')"
                      @change="initData()"
                    ></el-date-picker>
                  </li> -->
							<!-- <li>
                  	<span>{{ $t('hytxs0000039') }}</span>
                  	<el-input
                  		type="text"
                  		v-model="filterData.keywords"
                  		size="small"
                  		clearable
                  		@keyup.enter.native="initData()"
                  		maxlength="50"
                  		:placeholder="$t('hytxs0000038')"
                  		style="width: 180px;"
                  	/>
                  </li> -->


							<!-- <li>
                    <span>{{$t('c944a6686d996ab3')}}</span>
                    <el-select
                    	size="small"
                    	filterable
                    	clearable
                    	v-model="filterData.whNo"
                    	:placeholder="$t('2ad108ab2c560530')"
                    	style="width: 200px;" 
                      @change="initData()"
                    >
                    	<el-option v-for="item in selectOption.wh_no" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')" :value="item.code"><div class="sel_option_s1" style="">
				<span class="sel_option_name">{{ item.companyName }}</span>
				<span class="sel_option_code" style="">{{ item.cusNo }}</span>
			</div></el-option>
                    </el-select>
                    
                  </li> -->
							<li>
								<span>快递</span>
								<el-select size="small" filterable clearable v-model="filterData.logistics"
									:placeholder="$t('2ad108ab2c560530')" style="width: 100px;" @change="initData()">
									<el-option :label="'全部'" :value="null"></el-option>
									<el-option :label="'FEDEX'" :value="'FEDEX'"></el-option>
									<el-option :label="'UPS'" :value="'UPS'"></el-option>
									<el-option :label="'USPS'" :value="'USPS'"></el-option>
								</el-select>
							</li>
							<li>
								<span>是否可用</span>
								<el-select size="small" filterable clearable v-model="filterData.isUse"
									:placeholder="$t('2ad108ab2c560530')" style="width: 100px;" @change="initData()">
									<el-option :label="'全部'" :value="null"></el-option>
									<el-option :label="'正常'" :value="'1'"></el-option>
									<el-option :label="'不可用'" :value="'0'"></el-option>
								</el-select>
							</li>
							<!-- <li>
										<span>{{ $t('hytxs0000034') }}</span>
										<el-input
											type="text"
											v-model="filterData.pArea"
											size="small"
											clearable
											@keyup.enter.native="initData()"
											maxlength="50"
											:placeholder="$t('hytxs0000001')"
											style="width: 180px;"
										/>
									</li>
									<li>
										<span>{{ $t('hytxs0000035') }}</span>
										<el-input
											type="text"
											v-model="filterData.place"
											size="small"
											clearable
											@keyup.enter.native="initData()"
											maxlength="50"
											:placeholder="$t('hytxs0000001')"
											style="width: 180px;"
										/>
									</li> -->
							<!-- </ul> -->
							<!-- <ul class="filterConList"> -->
							<li>
								<span>{{$t('i18nn_f353b1ebbdc25be4')}}</span>
								<el-input type="text" v-model="filterData.keyword" size="small" clearable
									@keyup.enter.native="initData()" maxlength="50" :placeholder="$t('custom_enterfuzzysearch')"
									style="width: 180px;" />
							</li>

							<li>
								<el-button icon="el-icon-search" size="small" type="primary"
									@click="initData()">{{ $t('1e7246dd6ccc5539') }}</el-button>
							</li>
						</ul>
						<!-- </el-col> -->
						<!-- </el-row> -->
					</div>
				</div>

				<el-table id="ex_table" ref="multipleTable" :data="tableData" stripe :border="true"
					:height="$store.state.tableMaxHeight3" @row-click="handleCurrentChange"
					@selection-change="handleSelectionChange" style="width: 100%" size="small">
					<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
						:label="$t('7b1c2b1adc920d9c')"></el-table-column>

					<!-- <el-table-column v-if="isSel" type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('7b1c2b1adc920d9c')"></el-table-column>
					<el-table-column v-else type="selection" fixed="left" align="center" width="50" label-class-name="nodra"></el-table-column> -->

					<!--  "id":"数据ID",
                "pArea":$t('hytxs0000034'),
                "place":$t('hytxs0000035') -->

					

					<el-table-column prop="isUseName" :label="$t('i18nn_a9dc5000637ff647')" min-width="100">
						<template slot-scope="scope">
							<el-tag type="danger"
								v-if="'0' == scope.row.isUse">{{ $Utils.i18nKeyText(scope.row,'isUseName') }}</el-tag>
							<el-tag type="success"
								v-else-if="'1' == scope.row.isUse">{{ $Utils.i18nKeyText(scope.row,'isUseName') }}</el-tag>
							<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'isUseName') }}</el-tag>
						</template>
					</el-table-column>

					<el-table-column prop="logistics" :label="$t('i18nn_81f6eaf23b9c56eb')" min-width="100">
					</el-table-column>
					<el-table-column prop="codeName" :label="'lgskey'" min-width="100">
					</el-table-column>

					<el-table-column prop="clientKey" :label="'clientKey'" min-width="150">
					</el-table-column>

					<el-table-column prop="clientSecret" :label="'clientSecret'" min-width="150">
					</el-table-column>

					<el-table-column prop="apiUrl" :label="'apiUrl'" min-width="150">
					</el-table-column>

					<!-- ExpressSheetAccount -->


					<el-table-column prop="" :label="$t('i18nn_1cacee9f09138a3d')" min-width="300">
						<template slot-scope="scope">
							<div>
								<div v-for="(item,index) in scope.row.channels" :key="index">
									<span>{{item.key}}:</span>
									<span>{{item.name}}</span>
								</div>
							</div>
						</template>
					</el-table-column>

					<el-table-column prop="" :label="$t('i18nn_ade48dee835e25c0')" min-width="150">
						<template slot-scope="scope">
							<div>
								<div v-for="(item,index) in scope.row.signatures" :key="index">
									<span>{{item.key}}:</span>
									<span>{{item.name}}</span>
								</div>
							</div>
						</template>
					</el-table-column>
					
					
					<el-table-column prop="" :label="$t('i18nn_ab6ea90b9164b20a')" min-width="200">
						<template slot-scope="scope">
							<div>
								<div v-for="(item,index) in scope.row.descJson" :key="index">
									<span>{{item.name}}:</span>
									<span>{{item.value}}</span>
								</div>
							</div>
						</template>
					</el-table-column>

					<el-table-column prop="remark" :label="$t('15b3627faddccb1d')" width="150" show-overflow-tooltip>
						
					</el-table-column>

					<el-table-column prop="orderSort" :label="$t('i18nn_dbcffdf31e86d2d8')" width="150" show-overflow-tooltip>
						
					</el-table-column>

					<el-table-column prop="updateTime" :label="$t('i18nn_fdc34fd7121f9c48')" width="150"></el-table-column>

					<el-table-column prop="createTime" :label="$t('i18nn_726c51702f70c486')" width="150"></el-table-column>
					
					<el-table-column prop="id" label="ID"></el-table-column>
					
					<el-table-column :label="$t('93f5ca01b006206c')" width="280px" fixed="right">
						<template slot-scope="scope">
							<div>
								<div v-if="isSel">
									<el-button @click="selRow($event, scope.row, scope.$index)" type="success" size="mini"
										icon="el-icon-magic-stick">
										{{ $t('7fb6ada66a1fccca') }}
									</el-button>
								</div>
								<div v-else>
									<el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini"
										icon="el-icon-edit">
										{{ $t('6267f3aedf895209') }}
									</el-button>

									<!-- <el-button @click="delAddAction($event, scope.row, scope.$index)" v-if="'add' == scope.row.dataFlag" type="warning" size="mini" icon="el-icon-minus">
									{{ $t('e33c9b93c36fd250') }}
								</el-button>
								<el-button
									@click="editRefreshAction($event, scope.row, scope.$index)"
									v-if="'edit' == scope.row.dataFlag"
									type="warning"
									size="mini"
									icon="el-icon-refresh-right"
								>{{$t('i18nn_619a3387e6517f01')}}</el-button>
								
								<el-button
									@click="saveAction($event, scope.row, scope.$index)"
									v-if="'add' == scope.row.dataFlag || 'edit' == scope.row.dataFlag"
									type="success"
									size="mini"
									icon="el-icon-check"
								>
									{{ $t('4ce9979bfb6576d9') }}
								</el-button> -->

									<el-button @click="delAction($event, scope.row)" type="danger" size="mini" icon="el-icon-minus">
										{{ $t('e33c9b93c36fd250') }}
									</el-button>
								</div>

								<div style="margin-top: 10px;">
									<el-button @click="queryBalenceAction($event, scope.row)" type="success" size="mini"
										icon="el-icon-search">{{$t('i18nn_8d337267addff5d5')}}</el-button>
								</div>
							</div>

						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="tableConPagination"><hy-page-pagination :pagination="pagination"
					v-on:updatePageData="getPageData"></hy-page-pagination></div>
		</div>

		<!-- 新增 -->
		<ExpressSheetAccountEdit :openTime="EditOpentTime" :row="EditRow" @success="EditSuccess"></ExpressSheetAccountEdit>

		<!-- excel 导出排序 -->
		<!-- <whExcelCustom :openTime="excelOpenTime" :excelHead="excelHead" :excelData="expExcelData" :pagination="pagination" :excelName="excelName" :excelOption="excelOption"
		:expHttpUrl="$urlConfig.WhPlacePageList" :expHttpFilter="pageFilterData()"></whExcelCustom> -->

	</div>
</template>
<script>
	// import JQ from 'jquery'
	import {
		deepClone
	} from '@/utils/utils.js';
	// import excelUtils from '@/utils/excelUtils.js';
	import {
		getDicData
	} from '@/axios/common.js';
	// import excelUtilsNew from '@/utils/excelUtilsNew.js';

	// import { htmlToPdf } from '@/utils/pdfUtils.js';

	// import barcode from '@/components/Common/barcode.vue';
	import ExpressSheetAccountEdit from '@/components/WarehouseCenter2/ExpressSheet/ExpressSheetAccountEdit.vue';
	// import whExcelCustom from '@/components/WarehouseCenter2/components/whExcelCustom.vue';


	export default {
		components: {
			// barcode,
			ExpressSheetAccountEdit
			// whExcelCustom
		},
		props: {
			// mobile:"",
			whNo: {
				default: function() {
					return '';
				},
				type: String
			},
			isSel: {
				default: function() {
					return false;
				},
				type: Boolean
			}
		},
		data() {
			return {
				//excel导出
				// excelOpenTime:'',
				// excelHead:[],
				// expExcelData:[],
				// excelName:"",
				// excelOption:{},
				EditOpentTime: "",
				EditRow: {},
				//打开入库选择框
				// dialogSelVisible: false,
				// dialogVisible2:false,
				// loading_pdf: false,
				// PdfData:[],
				//选择
				currentSel: {},
				//表格数据
				//loading,表格数据
				loading: false,
				loading_load: false,
				//表格数据
				tableData: [],
				//缓存，原数据
				// tableDataCatch: [],

				//分页数据
				pagination: this.$Utils.defaultPagination(),
				//表格选择的数据
				multipleSelection: [],
				//查询，排序方式
				//"orderBy": "auditTime,fCon,goodsCon", //排序字段
				selectOption: {
					// wh_no: [],
					// biz_wh_lgskey_code: [],
					// isUse: [{
					// 	code: "0",
					// 	codeText: this.$t('i18nn_ab5902ee5c645e01'),
					// }, {
					// 	code: "1",
					// 	codeText: this.$t('i18nn_e0199d6ecc3ee81a'),
					// }, ]
				},

				filterData: {
					orderBy: 'create_time', //排序字段
					sortAsc: 'desc', //desc降序，asc升序

					whNo: '',
					keywords: '',
					// pArea: '', //this.$t('hytxs0000034'),
					// place: '', //this.$t('hytxs0000035'),
					keyword: "",
					// wh_place_type: "",
					isUse: "",
					logistics: ""
					// plStatue: '',
					// plNo: '',
					// putWhNo: '',
					// goodsSku: '',
					// goodsName: ''
				}
			};
		},
		// watch: {
		// 	whNo(to, from) {
		// 		this.filterData.whNo = this.whNo;
		// 		this.initData();
		// 	}
		// },
		activated() {
			// if (this.$route.query && this.$route.query.plNo) {
			// 	this.filterData.plNo = this.$route.query.plNo;
			// }
			this.initData();
		},
		//创建时
		created() {
			// if (this.$route.query && this.$route.query.plNo) {
			// 	this.filterData.plNo = this.$route.query.plNo;
			// }
			// this.initData();

		},
		//编译挂载前
		mounted() {
			if (this.whNo) {
				this.filterData.whNo = this.whNo;
				this.initData();
			}
			// this.getDicData();
			//数据字典
			// getDicData(['biz_wh_lgskey_code'],
			// 	(data) => {
			// 		this.selectOption.biz_wh_lgskey_code = data['biz_wh_lgskey_code'];
			// 	});
			// this.getWhNoData();
		},
		methods: {
			initData() {
				//跳转到第一页
				this.pagination.current_page = 1;
				this.currentSel = {};
				this.multipleSelection = [];
				this.initTable();
				this.getPageData();
				// 部门数据
				// this.getProvinceData();
				// this.getDetData("");

			},
			initTable() {
				this.$nextTick(() => {
					this.$refs.multipleTable.doLayout();
				});
				this.$forceUpdate();
			},
			//导出 excel
			// columnsExcel() {

			// 	let columns = [
			// 		{
			// 			title: 'ID',
			// 			key: 'id'
			// 		},
			// 		{
			// 			title: this.$t('5acbec83efb27445'),
			// 			key: 'whNo'
			// 		},
			// 		{
			// 			title: this.$t('hytxs0000034'),
			// 			key: 'pArea'
			// 		},
			// 		{
			// 			title: this.$t('hytxs0000035'),
			// 			key: 'place'
			// 		},
			// 		{
			// 			title: this.$t('hytxs0000036'),
			// 			key: 'placeTypeName'
			// 		},
			// 		{
			// 			title: this.$t('hytxs0000037'),
			// 			key: 'totalStock',
			// 			types: 'custom',
			// 			formatter:function(row){
			// 				if(row.placeStockTotal && row.placeStockTotal.totalStock){
			// 					return row.placeStockTotal.totalStock
			// 				} else {
			// 					return 0;
			// 				}

			// 			}
			// 		},
			// 		{
			// 			title: this.$t('i18nn_1d5fb99b98e26365'),
			// 			key: 'barcodeimg',
			// 			types: 'image'
			// 		},
			// 	];
			// 	// let Data = this.tableDataCatch;
			// 	return columns;

			// },
			// exportExcelAction(){
			// 	let columns = [
			// 		{
			// 			title: 'ID',
			// 			key: 'id'
			// 		},
			// 		{
			// 			title: this.$t('5acbec83efb27445'),
			// 			key: 'whNo'
			// 		},
			// 		{
			// 			title: this.$t('hytxs0000034'),
			// 			key: 'pArea'
			// 		},
			// 		{
			// 			title: this.$t('hytxs0000035'),
			// 			key: 'place'
			// 		},
			// 		{
			// 			title: this.$t('hytxs0000036'),
			// 			key: 'placeTypeName'
			// 		},
			// 		{
			// 			title: this.$t('hytxs0000037'),
			// 			key: 'totalStock',
			// 			types: 'custom',
			// 			formatter:function(row){
			// 				if(row.placeStockTotal && row.placeStockTotal.totalStock){
			// 					return row.placeStockTotal.totalStock
			// 				} else {
			// 					return 0;
			// 				}

			// 			}
			// 		},
			// 		{
			// 			title: this.$t('i18nn_1d5fb99b98e26365'),
			// 			key: 'barcodeimg',
			// 			types: 'image'
			// 		},
			// 	];
			// 	// let Data = this.tableDataCatch;
			// 	// return columns;
			// 	let Data = this.tableDataCatch;

			// 	let data_dom = JQ('#ex_table').find(".el-table__body");
			// 	let obj_key_img = {};
			// 	// console.log(data_dom.find("tr"));
			// 	data_dom.find("tr").each((index, domEle)=>{
			// 		// console.log("index",index);
			// 		let imgObj = JQ(domEle).find("td .cell img");
			// 		// console.log("imgObj",imgObj);
			// 		if(imgObj){
			// 			obj_key_img[imgObj.attr("code")] = imgObj.attr("src");
			// 		}
			// 	})
			// 	// console.log("obj_key_img",obj_key_img);
			// 	Data.forEach(item=>{
			// 		item.barcodeimg = obj_key_img[item.whNo + '&' + item.place];
			// 	})
			// 	// console.log('Data',Data);
			// 	// return;
			// 	this.expExcelData = Data;
			// 	this.excelHead = columns;
			// 	this.excelName = 'wh_place';
			// 	this.excelOption = {};
			// 	this.excelOpenTime = new Date().getTime();

			// 	// excelUtilsNew.exportExcel(columns, Data, 'wh_place');
			// },
			// exportExcelAction2(){
			// 	// this.exportExcel(this.PdfData);
			// 	let columns = [
			// 		// {
			// 		// 	title: 'ID',
			// 		// 	key: 'id'
			// 		// },
			// 		// {
			// 		// 	title: this.$t('5acbec83efb27445'),
			// 		// 	key: 'whNo'
			// 		// },
			// 		// {
			// 		// 	title: this.$t('hytxs0000034'),
			// 		// 	key: 'pArea'
			// 		// },
			// 		// {
			// 		// 	title: this.$t('hytxs0000035'),
			// 		// 	key: 'place'
			// 		// },
			// 		// {
			// 		// 	title: this.$t('hytxs0000036'),
			// 		// 	key: 'placeTypeName'
			// 		// },
			// 		// {
			// 		// 	title: this.$t('hytxs0000037'),
			// 		// 	key: 'totalStock',
			// 		// 	types: 'custom',
			// 		// 	formatter:function(row){
			// 		// 		if(row.placeStockTotal && row.placeStockTotal.totalStock){
			// 		// 			return row.placeStockTotal.totalStock
			// 		// 		} else {
			// 		// 			return 0;
			// 		// 		}

			// 		// 	}
			// 		// },
			// 		{
			// 			title: this.$t('i18nn_1d5fb99b98e26365'),
			// 			key: 'barcodeimg',
			// 			types: 'image'
			// 		},
			// 	];
			// 	// let Data = this.tableDataCatch;
			// 	// return columns;
			// 	let Data = this.PdfData;

			// 	let data_dom = JQ('#pdfPage').find("table");
			// 	let obj_key_img = {};
			// 	// console.log(data_dom.find("tr"));
			// 	data_dom.find("tr").each((index, domEle)=>{
			// 		// console.log("index",index);
			// 		let imgObj = JQ(domEle).find("td img");
			// 		// console.log("imgObj",imgObj);
			// 		if(imgObj){
			// 			obj_key_img[imgObj.attr("code")] = imgObj.attr("src");
			// 		}
			// 	})
			// 	// console.log("obj_key_img",obj_key_img);
			// 	Data.forEach(item=>{
			// 		item.barcodeimg = obj_key_img[item.whNo + '&' + item.place];
			// 	})
			// 	// console.log('Data',Data);
			// 	// return;
			// 	excelUtilsNew.exportExcel(columns, Data, 'wh_place');
			// },
			//打开选择数据
			// openSelData(event, row, index) {
			//   event.stopPropagation();

			//   this.dialogSelVisible = true;
			//   this.openRowData = row;
			//   this.openRowIndex = index;
			//   // this.$nextTick(() => {
			//   //   this.$refs.hyUpLoadImg1.openFile();
			//   // });
			// },
			// //选择数据后回调
			// selWhGoodsData(selRow) {
			//   this.dialogSelVisible = false;

			//   let row = this.openRowData;
			//   row.putWhNo = selRow.putWhNo;
			//   row.goodsSku = selRow.goodsSku;
			//   row.goodsImg = selRow.goodsImg;
			//   row.goodsName = selRow.goodsName;
			//   this.$set(this.tableData, this.openRowIndex, row);

			//   // this.openRowData = row;
			//   // this.openRowIndex = index;
			//   // this.$nextTick(() => {
			//   //   this.$refs.hyUpLoadImg1.openFile();
			//   // });
			// },
			//选择仓库
			changeDataWhNo(data) {
				console.log('changeDataWhNo', data);
				this.form.whNo = data.code;
			},

			//打开弹窗 下载库位二维码 PDF
			// openDownCodePDF(){
			// 	console.log('openDownCodePDF');
			// 	if(!this.filterData.whNo){
			// 		this.$message.warning(this.$t('i18nn_6ac98f81bd978520'));
			// 		return;
			// 	}
			// 	this.dialogVisible2 = true;
			// 	this.PdfData = [];
			// 	this.getPdfData();

			// },
			//下载库位二维码 PDF
			// PdfDownLoad(){
			// 	console.log('PdfDownLoad');
			// 	// this.$nextTick(()=>{
			// 	// 	htmlToPdf("pdfPage",this.filterData.whNo+'_wh_place');
			// 	// });
			// },
			// getPdfData(){
			// 	this.loading_pdf = true;

			// 	let filterData = Object.assign({
			// 		offset: 0, //当前页
			// 		limit: 5000, //当前页显示数目
			// 	},this.pageFilterData())

			// 	this.$http
			// 		.put(this.$urlConfig.WhPlacePageList, filterData)
			// 		.then(({ data }) => {
			// 			console.log('列表，请求成功');
			// 			console.log(data);
			// 			this.loading_pdf = false;
			// 			if (200 == data.code) {
			// 				//表格显示数据
			// 				this.PdfData = data.rows;
			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a1761c0e88af75ff'));
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('列表，请求失败');
			// 			this.$message.error(this.$t('hytxs0000030'));
			// 			this.loading_pdf = false;
			// 		});
			// },

			//作为组件选择行数据
			selRow(event, row, index) {
				event.stopPropagation();
				this.$emit('selectRow', row);
			},
			//打开图片上传
			// openUpLoadImg(row, index) {
			//   this.dialogFormVisible = true;
			//   this.openRowData = row;
			//   this.openRowIndex = index;
			//   this.$nextTick(() => {
			//     this.$refs.hyUpLoadImg1.openFile();
			//   });
			// },
			//清除图片
			// clearUpLoadImg(row,index){
			//   row.goodsImg = '';
			//   this.$set(this.tableData, index, row);
			// },

			//图片改变
			// changeImgUrl1(childValue) {
			//   console.log('changeImgUrl1', childValue);
			//   // childValue就是子组件传过来的值

			//   let row = this.openRowData;
			//   row.goodsImg = childValue;
			//   this.$set(this.tableData, this.openRowIndex, row);
			//   this.dialogFormVisible = false;
			//   // this.$set(this.tableDataCatch, this.openRowIndex, row);
			//   // this.myForm.imgList = [childValue];
			// },
			//新增
			openAdd(event, row) {
				event.stopPropagation();
				this.EditRow = row;
				this.EditOpentTime = new Date().getTime();
			},

			EditSuccess() {
				this.initData();
			},

			//查看图片
			// openBigImg(event, imgsrc) {
			//   event.stopPropagation();
			//   console.log('openBigImg', imgsrc);
			//   this.dialogImgVisible = true;
			//   this.imgUrlBigShow = imgsrc;
			// },

			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				this.multipleSelection = val;
			},
			//新增描述
			// addContacAction(row,index){
			//   console.log('addContacAction',index);
			// 	if(row.descJson){
			// 		row.descJson.push({ name: this.$t('i18nn_ab6ea90b9164b20a'), value: '' });
			// 	} else {
			// 		row.descJson = [];
			// 	}
			//   // this.initTable();
			// },
			// //删除联系方式
			// delContacAction(row,index){
			//   console.log('delContacAction',index);
			//   row.descJson.splice(index,1);
			// 	// this.initTable();
			// },

			//三方渠道
			// addChannelsAction(row,index){
			//   console.log('addChannelsAction',index);
			// 	if(row.channels){
			// 		row.channels.push({ key: '', name: '' });
			// 	} else {
			// 		row.channels = [];
			// 	}
			//   // this.initTable();
			// },
			// //删除
			// delChannelsAction(row,index){
			//   console.log('delSignaturesAction',index);
			//   row.channels.splice(index,1);
			// 	// this.initTable();
			// },

			//签名
			// addSignaturesAction(row,index){
			//   console.log('addSignaturesAction',index);
			// 	if(row.signatures){
			// 		row.signatures.push({ key: '', name: '' });
			// 	} else {
			// 		row.signatures = [];
			// 	}
			//   // this.initTable();
			// },
			// //删除
			// delSignaturesAction(row,index){
			//   console.log('delSignaturesAction',index);
			//   row.signatures.splice(index,1);
			// 	// this.initTable();
			// },

			//新增
			// addAction() {
			// 	console.log('addAction');
			// 	if (!!this.tableData[0] && !this.tableData[0].id) {
			// 		this.$alert(this.$t('i18nn_b4cd5c00f153455d'), this.$t('cc62f4bf31d661e3'), {
			// 			type: 'warning'
			// 		});
			// 		return;
			// 	}
			// 	this.tableData.unshift({
			// 		dataFlag: 'add',
			// 		descJson: [{ name: this.$t('i18nn_ab6ea90b9164b20a'), value: '' }],
			// 		channels:[],
			// 		signatures:[],
			// 		// "id":"数据ID",
			// 		//       "pArea":this.$t('hytxs0000034'),
			// 		//       "place":this.$t('hytxs0000035')

			// 		// "id":"",
			// 		// pArea: '',
			// 		// place: '',
			// 		// remark: ''
			// 	});
			// 	// this.initTable();
			// },
			//删除新增
			// delAddAction(event, row, index) {
			// 	event.stopPropagation();
			// 	console.log('delAddAction', index);
			// 	this.tableData.splice(index, 1);
			// },
			//编辑
			openEdit(event, row, index) {
				event.stopPropagation();
				console.log('openEdit', row);
				this.openAdd(event, row);
				// row.dataFlag = 'edit';
				// this.$set(this.tableData, index, row);
				// // this.initTable();
				// this.$refs.multipleTable.toggleRowSelection(row, true);
			},
			//取消编辑，还原数据
			// editRefreshAction(event, row, index) {
			// 	event.stopPropagation();
			// 	console.log('editRefreshAction', row);
			// 	// row.dataFlag = '';
			// 	console.log('old_row_data11', this.tableDataCatch);
			// 	// let old_row_data = row;
			// 	let old_row_data_f = this.tableDataCatch.find(item => {
			// 		return item.id == row.id;
			// 	});
			// 	let old_row_data = deepClone(old_row_data_f); //深拷贝

			// 	// let old_row_data = Object.assign(old_row_data_f,{});//浅拷贝
			// 	// let old_row_data_index = this.tableDataCatch.indexOf(item=>{
			// 	//   return item.id == row.id;
			// 	// });
			// 	// let old_row_data = this.tableDataCatch[old_row_data_index];
			// 	// .map(function (item) { //接收新数组
			// 	//   return item * item;
			// 	// });
			// 	console.log('old_row_data', old_row_data);
			// 	// this.tableData
			// 	this.$set(this.tableData, index, old_row_data);
			// 	// this.initTable();
			// },
			//保存
			// saveAction(event, row, index) {
			// 	event.stopPropagation();
			// 	console.log('saveAction', row);
			// 	// if(!row.codeName){
			// 		let curLgsKey = this.selectOption.biz_wh_lgskey_code.find(item=>{
			// 			return item.code==row.code;
			// 		});
			// 		if(curLgsKey){
			// 			row.codeName = curLgsKey.codeText;
			// 		}
			// 		row.extendParamJson = {
			// 			channels:row.channels,
			// 			signatures:row.signatures,
			// 		};
			// 	// }
			// 	this.postData(this.$urlConfig.WhExpreeSheetAccountUpdate, row, () => {
			// 		if (row.id) {
			// 			//不刷新数据，仅改变编辑状态
			// 			// row.dataFlag = null;
			// 			// this.$set(this.tableData, index, row);
			// 			// this.$set(this.tableDataCatch, index, row);
			// 			// this.$refs.multipleTable.toggleRowSelection(row, true);
			// 			// this.initTable();
			// 			// this.$alert('恭喜,编辑成功！', this.$t('cc62f4bf31d661e3'), {
			// 			//   type: 'success',
			// 			//
			// 			// });
			// 			this.initData();
			// 			this.$message.success(this.$t('i18nn_994cb374e601fab3'));
			// 		} else {
			// 			this.initData();
			// 			// this.$alert('恭喜,新增成功！', this.$t('cc62f4bf31d661e3'), {
			// 			//   type: 'success',
			// 			//
			// 			// });
			// 			this.$message.success(this.$t('i18nn_4ab989bd6abd3ac7'));
			// 		}
			// 	});
			// },
			//完成收货
			// completeAction(event, row) {
			//   event.stopPropagation();
			//   console.log('completeAction', row);
			//   let parm = [];

			//   if (!!row) {
			//     //单条
			//     parm = [row.id];
			//   } else {
			//     //多条
			//     let dataList = this.multipleSelection;
			//     if (dataList.length < 1) {
			//       this.$message.warning(this.$t('7b80e66b535a3732'));
			//       return;
			//     }

			//     let dataListParm = dataList.map(v => v.id);
			//     parm = dataListParm;
			//     // console.log('dataListParm', dataListParm);
			//     // let dataParm = {
			//     //   ids: dataListParm
			//     // };
			//   }

			//   this.postData(this.$urlConfig.WhShelvesComplete, parm, () => {
			//     this.initData();
			//     //  this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
			//     //   type: 'success',
			//     //
			//     // });
			//     this.$message.success(this.$t('9f30371831a98237'));
			//     // if(row.id){
			//     //   row.dataFlag = '';
			//     //   this.$alert('恭喜,编辑成功！', this.$t('cc62f4bf31d661e3'), {
			//     //     type: 'success',
			//     //
			//     //   });
			//     // } else {
			//     //   this.initData();
			//     //   this.$alert('恭喜,新增成功！', this.$t('cc62f4bf31d661e3'), {
			//     //     type: 'success',
			//     //
			//     //   });
			//     // }
			//   });
			// },
			//余额查询
			queryBalenceAction(event, row) {
				event.stopPropagation();
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhExpreeSheetQueryBalence, {
						"lgsKeyCode": row.code
					})
					.then(({
						data
					}) => {
						console.log('查询，请求成功');
						console.log(data);
						this.loading_load = false;
						if (200 == data.code && data.data) {
							// this.selectOption.wh_no = data.rows;
							let balance = data.data.balance;
							if (balance) {
								this.$alert('余额：' + data.data.balance, this.$t('cc62f4bf31d661e3'), {
									type: 'success',
								});
							} else {
								this.$alert(data.data.msg, this.$t('cc62f4bf31d661e3'), {
									type: 'warning',
								});
							}

						} else {
							if (!data.msg) {
								data.msg = this.$t('i18nn_74b62cbb161a84a4') + ',' + this.$t('i18nn_4eb3309d6692dbd4');
							}
							this.$message.warning(data.msg);
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('i18nn_323ee425eca7208c'));
						this.loading_load = false;
						this.$message.error(this.$t('i18nn_74b62cbb161a84a4') + ',' + this.$t('i18nn_4eb3309d6692dbd4') + '！');
					});
			},
			//删除
			delAction(event, row) {
				this.$confirm(this.$t('i18nn_d96afd0c0de3686c') + '?', this.$t('daaaeb1b7b22b126'), {
						type: 'warning'
					})
					.then(() => {
						// this.$message({
						//   type: 'success',
						//   message: '删除成功!'
						// });
						this.delDataAction(event, row);
					})
					.catch(() => {
						// this.$message({
						//   type: 'info',
						//   message: this.$t('i18nn_2e58cb9b52e2a214')
						// });
					});
			},
			//删除
			delDataAction(event, row) {
				event.stopPropagation();
				console.log('delDataAction', row);
				// let parm = [];

				// if (row) {
				// 	//单条
				// 	parm = [row.id];
				// } else {
				// 	//多条
				// 	// let dataList = this.multipleSelection;
				// 	// if (dataList.length < 1) {
				// 	// 	this.$message.warning(this.$t('7b80e66b535a3732'));
				// 	// 	return;
				// 	// }

				// 	// let dataListParm = dataList.map(v => v.id);
				// 	// parm = dataListParm;
				// 	// console.log('dataListParm', dataListParm);
				// 	// let dataParm = {
				// 	//   ids: dataListParm
				// 	// };
				// }

				this.postData(this.$urlConfig.WhExpreeSheetAccountDel + "/" + row.id, {}, () => {
					this.initData();
					this.$message.success(this.$t('9f30371831a98237'));
				}, 'delete');
			},

			//分页的筛选项数据
			pageFilterData() {
				return {
					whNo: this.filterData.whNo ? this.filterData.whNo : null,
					// place: this.filterData.place ? this.filterData.place : null,
					// pArea: this.filterData.pArea ? this.filterData.pArea : null,
					keyword: this.filterData.keyword ? this.filterData.keyword : null,
					// placeType: this.filterData.wh_place_type ? this.filterData.wh_place_type : null,
					isUse: this.filterData.isUse ? this.filterData.isUse : null,
					logistics: this.filterData.logistics ? this.filterData.logistics : null,
				};
			},

			//请求分页数据
			getPageData() {
				this.loading_load = true;
				// console.log('filterData1',this.pageFilterData());
				let filterData = Object.assign({
					offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
					limit: this.pagination.page_size, //当前页显示数目
				}, this.pageFilterData())

				// console.log('filterData2',filterData);
				this.$http
					.put(this.$urlConfig.WhExpreeSheetAccountPageList, filterData)
					.then(({
						data
					}) => {
						console.log('列表，请求成功');
						console.log(data);

						this.loading_load = false;
						if (200 == data.code) {
							let rowData = data.rows;
							rowData.forEach(item => {
								if (item.descJson) {
									// try {
									// 	item.descJson = item.descJson;
									// } catch(e) {
									// 	//TODO handle the exception
									// 	item.descJson = [];
									// 	console.log(e);
									// }
								} else {
									item.descJson = [];
								}
								if (item.extendParamJson) {
									if (item.extendParamJson.channels) {
										item.channels = item.extendParamJson.channels;
									} else {
										item.channels = [];
									}

									if (item.extendParamJson.signatures) {
										item.signatures = item.extendParamJson.signatures;
									} else {
										item.signatures = [];
									}

									// item.signatures = item.extendParamJson.signatures;
									// try {
									// 	item.descJson = item.descJson;
									// } catch(e) {
									// 	//TODO handle the exception
									// 	item.descJson = [];
									// 	console.log(e);
									// }
								} else {
									item.extendParamJson = {};
								}
								// extendParamJson = {
								// 	channels:row.channels,
								// 	signatures:row.signatures,
								// }
							});

							//表格显示数据
							this.tableData = rowData;
							//取消编辑时调用
							//缓存，待编辑的数据
							// this.tableDataCatch = data.rows;//直接赋值为浅拷贝 concat也是浅拷贝
							// this.tableDataCatch = deepClone(rowData);
							//当前数据总条数
							this.pagination.total = parseInt(data.total);
							//当前页数
							// this.pagination.current_page = parseInt(data.current);
							//当前页条数
							// this.pagination.page_size = parseInt(data.size);
							// this.$message.success('订单列表，请求成功');
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a1761c0e88af75ff'));
						}
					})
					.catch(error => {
						console.log(error);
						console.log('列表，请求失败');
						this.$message.error(this.$t('hytxs0000030'));
						this.loading_load = false;
					});
			},

			//提交信息
			postData(url, formData, callBack, type) {
				// let _this = this;
				this.loading = true;
				// formData.state = formData.state ? '1' : '0';
				let httpPro = null;
				if ('delete' == type) {
					httpPro = this.$http.delete(url, {})
				} else {
					httpPro = this.$http.put(url, formData)
				}
				httpPro.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);

						this.loading = false;
						if (200 == data.code) {
							// this.dialogFormVisible = false;
							// this.dialogAddFormVisible = false;
							// this.initData();
							callBack();
						} else {
							if (!data.msg) {
								data.msg = this.$t('dbe331ab679cd67f');
							}
							this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('i18nn_a7d2e953195a5588'));
						this.loading = false;
						this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});
			},
			//查询仓库
			// getWhNoData() {
			// 	this.$http
			// 		.get(this.$urlConfig.whNoPageList)
			// 		.then(({ data }) => {
			// 			console.log('查询，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.rows) {
			// 				this.selectOption.wh_no = data.rows;
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = '查询仓库失败,请重试';
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('i18nn_323ee425eca7208c'));
			// 			this.$message.error('查询仓库失败,请重试！');
			// 		});
			// },
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['biz_wh_lgskey_code'])
			// 		.then(({ data }) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				// this.selectOption.wh_size_unit = data.data['wh_size_unit'];
			// 				// this.selectOption.wh_car_tms_type = data.data['wh_car_tms_type'];
			// 				// this.selectOption.wh_no = data.data['wh_no'];
			// 				this.selectOption.biz_wh_lgskey_code = data.data['biz_wh_lgskey_code'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('hytxs0000032');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('hytxs0000033'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// /deep/ .el-table__body {
	// 	.el-input {
	// 		input {
	// 			padding-left: 5px;
	// 			padding-right: 5px;
	// 		}
	// 	}
	// 	.el-input-number {
	// 		width: 90px;
	// 		.el-input__inner {
	// 			text-align: left;
	// 		}
	// 	}
	// }
</style>